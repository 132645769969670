// **************
// タブ機能
// **************
const tabToggle = () => {
  // タブボタンにクリックイベントを追加
  const tabButtons = document.querySelectorAll('.js-tab-button');
  tabButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      openTab(button.getAttribute('data-tab'));
    });
  });

  // URLのパラメータを取得
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get('tab');

  // ロード時にURLのパラメータがあれば該当するタブを開く
  if (tabParam && Number(tabParam) <= tabButtons.length) {
    openTab('tab' + tabParam);
  }

  // タブを切り替える関数
  function openTab(tabId) {
    // すべてのタブの表示を非表示にする
    const tabContents = document.querySelectorAll('.js-tab-content');
    tabContents.forEach(function (tabContent) {
      tabContent.classList.remove('is-active');
    });
    // タブのボタン
    tabButtons.forEach(function (tabButton) {
      tabButton.classList.remove('is-active');
    });

    // クリックされたタブを表示する
    const activeTab = document.getElementById(tabId);
    activeTab.classList.add('is-active');
    // タブのボタン
    const activeTabBtn = document.querySelector('[data-tab="'+tabId+'"]');
    activeTabBtn.classList.add('is-active');
  }
}


// **************
// フッター固定
// **************

const adjustFooterPosition = () => {
  const bodyHeight = document.body.clientHeight;
  const windowHeight = window.innerHeight;
  const footer = document.getElementById('js-footer');
  const footerHeight = footer.clientHeight

  if (bodyHeight < windowHeight) {
    footer.classList.add('is-fixed');
    // footer.previousElementSibling
  } else {
    footer.classList.remove('is-fixed');
  }
}


// =================================================================

window.addEventListener('load', () => {
  tabToggle()
  adjustFooterPosition();
});

window.addEventListener('resize', () => {
  adjustFooterPosition();
});
